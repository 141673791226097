import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import LinkExternal from "../../components/link_external"
import Seo from "../../components/seo"
import Routes from "../../routes"
import Hitchens from "../../images/hitchens.jpg"
import { useImpression } from "../../analytics/utils"

const ChristopherHitchens = ({ location }) => {
  useImpression("ChristopherHitchens")
  return (
    <Layout location={location}>
      <Seo
        title="The Best of Christopher Hitchens and Hitchslaps"
        description="English-American writer and journalist Christopher Hitchens is famous for his religious intolerance and historic debate moments."
      />
      <h1>The Best of Christopher Hitchens and the Famous Hitchslaps</h1>
      <img
        src={Hitchens}
        alt="Christopher Hitchens portrait"
        width="400"
        style={{
          float: "right",
          padding: 20,
          textAlign: "center",
        }}
      />
      <h2>Who is Christopher Hitchens?</h2>
      <p>
        Hitchens, born in 1949, is famous for his powerful dialogues and debate
        moments, his thought-provoking books, and his social and religious
        critiques. He's become a fairly controversial figure, due to his
        confrontational debating style. Yet, he's seen as a highly respected
        intellectual by most. He held a career as a journalist in the United
        Kingdom from 1971&mdash;1981.
      </p>
      <p>
        As a relatively famous anti-theist, Hitchens considered all religions
        false in the same way; he found them authoritative and harmful to
        society. He describes himself as anti-totalitarian, a democratic
        socialist, and even a Marxist.
      </p>
      <h2>What is a Hitchslap</h2>
      <p>
        <LinkExternal to="https://www.urbandictionary.com/define.php?term=Hitchslap">
          Urban dictionary
        </LinkExternal>{" "}
        tells us that a hitchslap is basically when you concisely annihilate an
        opponent's argument (around politics and religion). He has tons of
        videos showing him doing exactly this.
      </p>
      <h2>Battle with cancer</h2>
      <p>
        Christopher suffered from a sudden pericardial effusion while touring to
        promote his books. It wasn't much longer that he announced he would
        undergo treatment for esophageal cancer. Sadly, Christopher Hitchens
        passed away on December 15, 2011, at age 62.
      </p>
      <h2>Best quotes and Hitchslaps</h2>
      <blockquote>
        "Gullibility and credulity are considered undesirable qualities in every
        department of human life... except religion." &mdash;
      </blockquote>
      <blockquote>
        "The gods that we've made are exactly the gods you'd expect to be made
        by a species that's about half a chromosome away from being chimpanzee."
        &mdash; University of Waterloo (2011)
      </blockquote>
      <blockquote>
        "Take the risk of thinking for yourself; much more happiness, truth,
        beauty, and wisdom will come to you that way."
      </blockquote>
      <hr />
      <p>
        Be sure to check out our <Link to={Routes.ATHEISM}>Atheism</Link> page
        to learn more.
      </p>
    </Layout>
  )
}

export default ChristopherHitchens
